<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <b-col>
              <h4>Search Users</h4>
            </b-col>
            <b-col class="text-right">
              <b-button variant="primary" size="sm" squared @click="addUser">Add User</b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <label>Name</label>
              <b-form-input v-model="user.name"></b-form-input>
            </b-col>
            <b-col cols="4">
              <label>Surname</label>
              <b-form-input v-model="user.surname"></b-form-input>
            </b-col>
            <b-col cols="4">
              <label>Email</label>
              <b-form-input v-model="user.email"></b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <label>Role</label>
              <b-form-select v-model="user.roleId">
                <b-form-select-option v-for="(item, index) in roles" :key="index" :value="item.roleID">{{item.description }}</b-form-select-option>
              </b-form-select>
            </b-col>
          </b-row>
          <hr class="mx-3">
          <b-row>
            <b-col class="text-right">
              <b-button variant="red" class="ml-2" squared @click="clearSearch" >Cancel</b-button>
              <b-button variant="primary" squared @click="searchResults" class="ml-2">Search</b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col>
        <b-card>
          <b-row>
            <b-col class="12">
              <div class="d-flex justify-content-end">
                  <b-button variant="primary" squared class="text-right" @click="doDownload">Export to .xls</b-button>
              </div>
              <b-table striped hover 
                       :items="tableData.dataSource" 
                       :fields="tableData.tableColumns"
                       :busy="tableData.isLoading" 
                       @row-clicked="openUser"
                       :per-page="tableData.resultsPerPage"
                       id="userSearch"
                       :current-page="tableData.currentPage" sort-icon-left>

                <template #table-busy>
                  <div class="text-center my-2">
                    <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                  </div>
                </template>

                <template #cell(lastLoginDate)="row">
                  <b-row align-v="center">
                    <span class="mr-auto">{{row.item.lastLoginDate | dateTimeFilter}}</span>
                  </b-row>
                </template>

                <template #cell(actions)="row">
                  <b-row align-v="center" align-h="end">
                    <b-button @click="openUser(row.item)" size="sm" class="btn-icon">
                      <b-icon-chevron-right></b-icon-chevron-right>
                    </b-button>
                  </b-row>
                </template>
              </b-table>
              <b-row align-h="center" >
                <b-pagination
                    v-model="tableData.currentPage"
                    :total-rows="rows"
                    :per-page="tableData.resultsPerPage"
                    aria-controls="userSearch"
                ></b-pagination>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import Vue from 'vue'
import {mapActions, mapMutations} from "vuex";
import JsonCSV from "vue-json-csv";

Vue.component('downloadCsv', JsonCSV)

export default {
  name: "userSearch",
  data: () => ({
    user: {
      userId: 0,
      name: null,
      surname: null,
      contactNumber: null,
      email: null,
      createdDate: null,
      lastLoginDate: null,
      role: null,
      roleID: 0,
      active: null,
    },
    tableData: {
      resultsPerPage: 10,
      currentPage: 1,
      dataSource: [],
      isLoading: true,
      tableColumns: [
        {
          label: 'Name',
          key: 'name',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Surname',
          key: 'surname',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Email',
          key: 'email',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Contact Number',
          key: 'contactNumber',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Role',
          key: 'role',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Last Logged In',
          key: 'lastLoginDate',
          sortable: true,
          tdClass: '',
        },
        {
          label: '',
          key: 'actions',
          sortable: false,
          tdClass: ''
        }
      ]
    },
    jsonData: [],
    updatedJsonData: [],
    roles: [],
    fileName: `Users ${new Date().toLocaleDateString('en-ZA')}.csv`
  }),
  created() {
    this.searchResults()
    this.userRole()
    this.setBreadcrumb([
      {
        text: 'Users'
      },
    ])
  },
  methods: {
    ...mapActions(['searchUsersRequest', "getUserRoles", 'downloadUserList']),
    ...mapMutations('breadcrumbService', ['setBreadcrumb']),
    ...mapActions('notificationService', ['addWarningMessage', 'addSuccessMessage']),
    doDownload(){
      let request = []
      
      if (this.user.name != null) {
        request.push({'key': 'name', 'value': this.user.name})
      }
      if (this.user.surname != null) {
        request.push({'key': 'surname', 'value': this.user.surname})
      }
      if (this.user.email != null) {
        request.push({'key': 'email', 'value': this.user.email})
      }
      if (this.user.roleId != null) {
        request.push({'key': 'fkroleId', 'value': this.user.roleId})
      }
      
      this.$store.commit('setUserSearchRequest', request)
      
      this.downloadUserList()
      .then((res) => {
        const linkSource = `data:${res.data.contentType};base64,${res.data.base64Data}`;
        const downloadLink = document.createElement("a");

        downloadLink.href = linkSource;
        downloadLink.download = res.data.fileName;
        downloadLink.click();
      })
    },
    searchResults() {
      this.tableData.isLoading = true
      
      let request = []
      
      if (this.user.name != null) {
        request.push({'key': 'name', 'value': this.user.name})
      }
      if (this.user.surname != null) {
        request.push({'key': 'surname', 'value': this.user.surname})
      }
      if (this.user.email != null) {
        request.push({'key': 'email', 'value': this.user.email})
      }
      if (this.user.roleId != null) {
        request.push({'key': 'fkroleId', 'value': this.user.roleId})
      }
      
      this.$store.commit('setUserSearchRequest', request)
      
      this.searchUsersRequest()
      .then((request) => {
        this.tableData.dataSource = request.data
        
        this.jsonData = this.tableData.dataSource.map(v => ({
          name: v.name,
          surname: v.surname,
          contactNumber: v.contactNumber,
          email: v.email,
          role: v.role,
          lastLoginDate: v.lastLoginDate === null ? '' : new Date(v.lastLoginDate).toLocaleDateString('en-ZA')
        }))
        
        this.tableData.isLoading = false
        this.state  = 'initialize'
      })
      .catch( () => {
        this.tableData.isLoading = false
      })
    },
    
    clearSearch() {
      this.user = {
        name: null,
        surname: null,
        email: null,
        role: null,
      }
      this.searchResults()
    },
    
    openUser(user) {
      this.$store.commit('setSelectedUser', user)
      this.$router.push({path: '/admin/userView'})
    },
    
    addUser() {
      this.$router.push({path: '/admin/userCreate'})
    },

    userRole() {
      this.getUserRoles()
          .then((response) => {
            this.roles = response.data
          })
    },

    downloadMessage() {
      if (this.jsonData.length === 0) {
        this.addWarningMessage('There is no data to be downloaded')
      } else {
        this.addSuccessMessage('Your content has been downloaded ')
      }
    },
    
  },
  computed: {
      rows() {
          return this.tableData.dataSource.length
      },
  },
}
</script>

<style scoped>

</style>